import './index.scss'
import _ from 'lodash'
import Swiper from 'swiper/js/swiper.min'
import 'swiper/css/swiper.min.css'
import 'particles.js'
import './verdor.js'
import '../../assets/scripts/swiper-bundle.min.js'

$(document).ready(function () {
  getScrollXH()
  setTimeout(() => {
    bannerSwiper()
    $('.loading').fadeOut()
  }, 2000);
});
// window.onload = function () {
//   setTimeout(() => {
//     bannerSwiper()
//     $('.loading').fadeOut()
//   }, 1000);
// }

function bannerSwiper() {
  let $bannerPaginationEl = $('.index-banner-pagination .list');
  let swiper = new Swiper('.banner-swiper', {
    spaceBetween: 30,
    effect: 'fade',
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    pagination: {
      el: $bannerPaginationEl,
      clickable: true,
      bulletClass: 'asdasdasdasd',
      bulletActiveClass: 'active',
      modifierClass: 'askdjwkdjdk',
      renderBullet: function (index, className) {
        return `
                  <svg class="item ${ className }" width="20" height="20">
                  <circle class="circle bg" cx="10" cy="10" r="8"/>
                  <circle
                  class="circle anime"
                  cx="10" cy="10" r="8"
                  style="animation-duration: ${ this.passedParams.autoplay.delay }ms"/>
                  </svg>
              `;
      }
    }
  })
}

// pc新闻播动
var swiper = new Swiper('.swiper-container1', {
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
        nextEl: '.swiper-button-next1',
        prevEl: '.swiper-button-prev1',
        disabledClass: 'my-button-disabled',
    },
    breakpoints: {
      768: {
        slidesPerView: 1.5,
        centeredSlides: true,
      },
      1024: {
        slidesPerView: 2.5,
      },
      1200: {
        slidesPerView: 2.5,
      },
    }
});

// 介绍 video
$('.video-play').on('click', function () {
//   $(this).find('.iconfont').hide()
  $('.video-cont').addClass('active').find('video').attr('autoplay', 'autoplay');
})

//媒体信息 swiper
var galleryThumbs = new Swiper('.gallery-thumbs', {
  slidesPerView: 3,
  freeMode: true,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  breakpoints: {
    640: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 5,
    },
    1024: {
      slidesPerView: 6,
    },
    1200: {
      slidesPerView: 9,
    },
  }
});
var galleryTop = new Swiper('.gallery-top', {
  autoplay:{
    delay: 5000,
    disableOnInteraction: false,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  thumbs: {
    swiper: galleryThumbs
  }
});

$('.vote-close').on('click',function(){
  $('.vote-mask').hide();
  $('.vote-img').addClass('small-vote')
})

$(window).resize(function () {
  getScrollXH()
})
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  $('.max_list li').eq(0).css('transform', 'translateY(-' + scroH / 50 + 'px)');
	$('.max_list li').eq(1).css('transform', 'translateY(' + scroH / 65 + 'px)');
  $('.max_list li').eq(2).css('transform', 'translateY(-' + scroH / 55 + 'px)');
  $('.max_list li').eq(3).css('transform', 'translateY(' + scroH / 30 + 'px)');

  //展会优势横向滚动
  if (document.body.clientWidth > 992) {
    var xscrollTop = $('.xscroll').offset().top
    var xscrollBtm = $('.xscroll').offset().top + $('.xscroll').height() - viewH

    console.log(xscrollTop,xscrollBtm)

    if (scroH < xscrollTop) {
      $('.xscroll .xscroll-tab-cont').removeClass('fixed')
    } else if (scroH >= xscrollTop && scroH < xscrollBtm) {
      $('.xscroll .xscroll-tab-cont').addClass('fixed')
      $('.xscroll .xscroll-tab-cont').removeClass('abs')
      // $('.xscroll  .xscroll-tab-cont .x').css('transform', 'translateX(-' + (scroH - xscrollTop) + 'px)')
    } else {
      $('.xscroll .xscroll-tab-cont').removeClass('fixed')
      $('.xscroll .xscroll-tab-cont').addClass('abs')
    }
    if (scroH >= xscrollTop && scroH < xscrollBtm){
      $('.xscroll  .xscroll-tab-cont .x').css('transform', 'translateX(-' + (scroH - xscrollTop) + 'px)')
    }

  }
});


//获取pc端产品区域高度
function getScrollXH() {
  if(document.body.clientWidth > 992){
    let xscrollH = $('.xscroll .x').outerWidth() - $(window).width() + $(window).height()

    console.log(xscrollH)
    $('.xscroll').css('height', xscrollH)

  }
}